import React from "react";
import classes from "./ConsentModal.module.css";
import useWindowWidth from "helpers/useWindowWidth";
import Button from "components/UI/button/Button";
import { useTranslation } from "react-i18next";

const ConsentModal = ({ handleAccept, handleReject }) => {
  const width = useWindowWidth();
  const { t } = useTranslation();

  return (
    <div
      className={`${classes.consentModal} ${
        width > 668 ? classes.consentLarge : classes.consentMob
      }`}
    >
      <h2 className={classes.title}>{t("cookie_usage")}</h2>
      <p className={classes.info}>
        {t("cookie_message")} {t("cookie_info_message")} -{" "}
        <span
          onClick={() => window.open("/informations/cookie-policy", "_blank")}
        >
          {t("cookie_policy")}
        </span>
      </p>
      <div className={classes.buttonsContainer}>
        <div className={classes.buttonsDiv}>
          <Button
            className={`${classes.button}`}
            onClick={() => handleAccept()}
          >
            {t("accept_all")}
          </Button>
          <Button className={classes.button} onClick={() => handleReject()}>
            {t("no_thank_you")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ConsentModal;
