// i18n.js
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector'; // Language detector plugin
import { initReactI18next } from 'react-i18next';
import enTranslations from './translations/en.json'; // English translations
import arTranslations from './translations/ar.json';
import heTranslations from './translations/he.json';

// // Lazy loading functions
// function loadArabicTranslations() {
//   return import('./translations/ar.json').then((module) => module.default);
// }

// function loadHebrewTranslations() {
//   return import('./translations/he.json').then((module) => module.default);
// }

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: {
      en: {
        translation: enTranslations // English translations
      },
      ar: {
        translation: arTranslations // English translations
      },
      he: {
        translation: heTranslations // English translations
      },
      // Arabic and Hebrew translations will be added dynamically
    },
    lng: localStorage.getItem('i18nextLng') || 'en', // Retrieve language from localStorage or default to 'en'
    fallbackLng: 'en', // Fallback language
    detection: {
      order: ['localStorage', 'navigator'],
      caches: ['localStorage'],
    },
    interpolation: {
      escapeValue: false,
    },
  });

  // Listen for language change events and store in localStorage
i18n.on('languageChanged', (lng) => {
    localStorage.setItem('i18nextLng', lng);
  });

export default i18n;
