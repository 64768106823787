import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    constants: null,
    settings: null,
    widgets: null,
    privacyPolicies: null,
};

const constantsSlice = createSlice({
    name: 'constants',
    initialState,
    reducers: {
        toggleConstants(state, action) {
            if (action.payload.constants) {
                state.constants = action.payload.constants;
            }
            if (action.payload.settings) {
                state.settings = action.payload.settings;
            }
            if (action.payload.widgets) {
                state.widgets = action.payload.widgets;
            }
            if (action.payload.privacyPolicies) {
                state.privacyPolicies = action.payload.privacyPolicies;
            }
        },
    }
});

export const constantsActions = constantsSlice.actions;
export default constantsSlice.reducer;
