import { Spin } from 'antd'
import React from 'react'
import classes from './LoadingFile.module.css'

function LoadingFile() {
  return (
    <div className={classes.spinOuter}>
      <Spin size="large" />
    </div>
  )
}

export default LoadingFile
