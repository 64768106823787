import { useDispatch, useSelector } from "react-redux";
import { modalActions } from "store-redux/modal-slice";
import SignInForm from "components/forms/sign-in/SignInForm";
// import Button from 'components/UI/button/Button';
// import FacebookIcon from 'components/UI/icons/FacebookIcon';
// import GoogleIcon from 'components/UI/icons/GoogleIcon';
// import TwitterIcon from 'components/UI/icons/TwitterIcon';
import ModalHeader from "components/UI/modal/ModalHeader";
import classes from "./SignIn.module.css";
import { useNavigate } from "react-router-dom";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import { Button, Modal } from "antd";
import { useState } from "react";

const SignIn = ({ onClose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const signupClickHandler = () => {
    dispatch(
      modalActions.changeModalComponent({
        modalType: "signup",
      })
    );
  };

  const [visible, setVisible] = useState(true);

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);

    // toggle the visibility of the Modal
    dispatch(modalActions.toggleModal());
    //to set input element value to buyer last bid
  };

  console.log("sign in modal loaded");

  const modalCloseIconStyle = {
    color: "red", // Change the color to red or any other color
    width: "20px",
    height: "20px",
  };

  return (
    <div className="signin-modal">
      <div className="modal-overlay" />
      <Modal
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        className={classes.signInModal}
        // style={{direction: "ltr"}}
        centered
        footer={null}
        closeIcon={
          <img
            src={`/news-img/icons/close-icon.svg`}
            alt="Close Icon"
            className={classes.modalIcon}
          />
        } // Custom close icon as an image
      >
        <SignInForm closeModal={() => handleCancel()} />
      </Modal>
    </div>
  );
};

export default SignIn;
