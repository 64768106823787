import React, { useContext, useState } from "react";
import classes from "./VerifiedContent.module.css";
import { useTranslation } from "react-i18next";
import { Modal } from "antd";
import useWindowWidth from "helpers/useWindowWidth";
import { useDispatch, useSelector } from "react-redux";
import { modalActions } from "store-redux/modal-slice";
import { ModalContext } from "components/layout/Layout";

function VerifiedContent({
  onClick,
  verificationStatus,
  verificationRemarks,
  verifiedBy,
  verifiedDate,
  history,
  showAllStatus,
}) {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const { language, direction } = useSelector((state) => state.language);

  const { openModal, closeModal } = useContext(ModalContext);

  const historyofVerified = history?.filter(
    (history) => history.status || history.verificationStatus
  );
  const newVerifiedBy =
    historyofVerified &&
    historyofVerified[historyofVerified?.length - 1]?.doneByName;
  const newVerifiedDate =
    historyofVerified && historyofVerified[historyofVerified?.length - 1]?.date;
  console.log(
    "666 historyofVerified, newVerifiedBy, newVerifiedDate",
    historyofVerified,
    newVerifiedBy,
    newVerifiedDate
  );

  const verified_icon = "/news-img/verified-icon.svg";
  const unverified_icon = "/news-img/unverified-icon.svg";
  const width = useWindowWidth();
  const dispatch = useDispatch();

  const handleCancel = (e) => {
    e.stopPropagation(); //to avoid working of onclick function of parent div
    setModalOpen(false);
    closeModal();
    // dispatch(modalActions.toggleModal());
  };

  const formatDateTo12Hour = (dateString) => {
    const date = new Date(dateString);
    const options = {
      month: "short",
      day: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
    return date.toLocaleString("en-US", options).replace(",", "");
  };

  const DateTimeDisplay = ({ dateString }) => {
    const formattedDate = formatDateTo12Hour(dateString);

    return (
      <div>
        <p>{formattedDate}</p>
      </div>
    );
  };

  const getVerifiedText = (status) => {
    if (showAllStatus) {
      switch (status) {
        case "verified":
          return t("verified");
        case "pending":
          return t("pending");
        case "rejected":
          return t("rejected");
        case "unverifiable":
          return t("unverifiable");

        default:
          return t("unverified");
      }
    } else {
      switch (status) {
        case "verified":
          return t("verified");
        case "unverifiable":
          return t("unverifiable");

        default:
          return t("unverified");
      }
    }
  };

  const getStatusMessage = (status) => {
    switch (status) {
      case "verified":
        return t("verified_message");
      case "pending":
        return t("pending");
      case "rejected":
        return t("rejected");
      case "unverifiable":
        return t("not_verified_message");

      default:
        return t("unverified");
    }
  };

  return (
    <>
      <div
        className={classes.verifiedOuter}
        onClick={(e) => {
          // dispatch(modalActions.toggleModal());
          e.stopPropagation(); //to avoid working of onclick function of parent div
          if (verificationStatus != "pending") {
            setModalOpen(!modalOpen);
            openModal();
          }
        }}
        style={{
          background:
            verificationStatus == "verified"
              ? "#1CCA00"
              : verificationStatus == "pending"
              ? "#E49B0F"
              : "#FF0000",
        }}
      >
        <img
          src={
            verificationStatus == "verified" ? verified_icon : unverified_icon
          }
        />
        <p>{getVerifiedText(verificationStatus)}</p>
      </div>
      <>
        {modalOpen && (
          <div className="modal-overlay" onClick={(e) => e.stopPropagation()} />
        )}
        <Modal
          open={modalOpen}
          // onOk={handleOk}
          onCancel={handleCancel}
          className={classes.signInModal}
          // wrapClassName={width > 1024 ? "contentModal" : ""}
          maskStyle={{ backgroundColor: "transparent" }}
          style={{
            direction: direction,
            top: width < 1550 ? "110px" : "200px",
          }}
          rootClassName="account-modal"
          // style={width > 1024 ? modalStyle : ""}
          footer={null}
          // centered={width > 1024 ? false : true} // Ensure the modal is not centered by default
          // centered={true} // Ensure the modal is not centered by default
          onClick={(e) => e.stopPropagation()}
          closeIcon={
            <img
              src={`/news-img/icons/close-icon.svg`}
              alt="Close Icon"
              className={classes.modalIcon}
            />
          }
        >
          <div
            className={classes.modalContainer}
            onClick={(e) => e.stopPropagation()}
          >
            <div className={classes.verifiedSection}>
              <img
                src={
                  verificationStatus == "verified"
                    ? "/news-img/icons/modal-verified.svg"
                    : "/news-img/icons/modal-notverified.svg"
                }
                className={classes.verifiedImg}
                alt=""
              />
              <h1 className={classes.verifiedTitle}>
                {getStatusMessage(verificationStatus)}
              </h1>
            </div>

            <h2 className={classes.name}>{verifiedBy}</h2>
            <h3 className={classes.time}>
              {formatDateTo12Hour(verifiedDate) != "Invalid Date"
                ? formatDateTo12Hour(verifiedDate)
                : ""}
            </h3>
            <p className={classes.remarks}>{verificationRemarks}</p>
          </div>
        </Modal>
      </>
    </>
  );
}

export default VerifiedContent;
