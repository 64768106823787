import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import Pagination from "react-js-pagination";
import "./PaginationCustom.module.css";
import ReactPaginate from "react-paginate";

const PaginationCustom = ({
  currentPage,
  setCurrentPage,
  totalItemsCount,
  itemsCountPerPage,
}) => {
  const [activePage, setActivePage] = useState(currentPage);
  const pageref = useRef();
  let pagesToDisplay;

  console.log("fixed values", totalItemsCount, itemsCountPerPage);

  if (window.innerWidth > 750) {
    pagesToDisplay = 4;
  } else if (window.innerWidth > 470 && window.innerWidth <= 750) {
    pagesToDisplay = 4;
  } else {
    pagesToDisplay = 2;
  }

  useEffect(() => {
    setCurrentPage(activePage);
  }, [activePage]);

  console.log("xyz page", activePage);

  const handlePageChange = (pageNumber) => {
    console.log(`active page is`, pageNumber);
    setActivePage(pageNumber.selected + 1);
  };

  console.log("page ref", pageref);

  const handlePrevButtonClick = () => {
    if (activePage > 1) {
      setActivePage(activePage - 1);
    }
  };

  const handleNextButtonClick = () => {
    // Assuming a total of 10 pages, you can adjust this based on your actual totalItemsCount
    const totalPages = Math.ceil(totalItemsCount / itemsCountPerPage);

    if (activePage < totalPages) {
      setActivePage(activePage + 1);
    }
  };

  return (
    // <div className="custom-pagination-wedding">
    //   <p
    //     onClick={handlePrevButtonClick}
    //     className="prev-button"
    //   >
    //     Prev
    //   </p>
    //   <Pagination
    //     ref={pageref}
    //     activePage={activePage}
    //     itemsCountPerPage={itemsCountPerPage}
    //     totalItemsCount={totalItemsCount}
    //     pageRangeDisplayed={pagesToDisplay}
    //     // pageRangeDisplayed={window.innerWidth>500 ? 5: (window.innerWidth<300 ? 2 : 3)}
    //     onChange={handlePageChange}
    //   />
    //   <p
    //     onClick={handleNextButtonClick}
    //     className="next-button"
    //   >
    //     Next
    //   </p>
    // </div>
    <div className="react-paginate">
      <ReactPaginate
        pageCount={Math.ceil(totalItemsCount / itemsCountPerPage)}
        pageRangeDisplayed={3}
        marginPagesDisplayed={1}
        forcePage={currentPage - 1} //to show the current page taken from url params
        onPageChange={handlePageChange}
        containerClassName="pagination"
        activeClassName="active"
        previousLabel={
          <img
            className="previous-arrow"
            src="/news-img/icons/pagination-left-arrow.svg"
            alt="Previous"
          />
        }
        nextLabel={
          <img
            className="previous-arrow"
            src="/news-img/icons/pagination-right-arrow.svg"
            alt="Previous"
          />
        }
        breakLabel={"..."}
      />
    </div>
  );
};

export default PaginationCustom;
