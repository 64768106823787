import { configureStore } from '@reduxjs/toolkit';

import modalReducer from './modal-slice';
import userReducer from './user-slice';
import languageReducer from './language-slice';
import constantsReducer from './constants-slice'

const store = configureStore({
  reducer: { modal: modalReducer, user: userReducer, language: languageReducer, constants: constantsReducer }
});

export default store;
