import LoadingSpinner from "components/UI/loading-spinner/LoadingSpinner";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

const ProtectedRoute = ({ children, isLoggedIn }) => {
  console.log("767 isLoggedIn", isLoggedIn);
  const location = useLocation();
  let navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn) {
      navigate(`/login?redirectRoute=${location.pathname}${location.search}`);
    }
  }, []);

  if (isLoggedIn) {
    return <div>{children}</div>;
  }
  // else {
  //   navigate(`/login?redirectRoute=${location.pathname}${location.search}`);
  //   return;
  // }
};

export default ProtectedRoute;
