import React, { useEffect, useState } from "react";
import classes from "./GalleryPage.module.css";
import { useSelector } from "react-redux";
import PaginationCustom from "components/pagination-custom/PaginationCustom";
import { Modal, Spin } from "antd";
import useWindowWidth from "helpers/useWindowWidth";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { API_BASE_URL } from "configs/AppConfig";
import { ArticlesDataKey, GalleryDataKey } from "helpers/queriesDataKeys";
import { useQuery } from "@tanstack/react-query";
import NoDataFound from "components/UI/no-data-found/NoDataFound";
import LoadingFile from "components/LoaderComponent/LoadingFile";
import GalleryGridPage from "./GalleryGridPage";

function GalleryPage({ data, formatWord, pageName, language }) {
  const { direction } = useSelector((state) => state.language);
  const user = useSelector((state) => state.user);
  const width = useWindowWidth();
  const { t } = useTranslation();

  const [modalOpen, setModalOpen] = useState(false);
  const [modalStyle, setModalStyle] = useState({});

  const navigate = useNavigate();
  const location = useLocation();

  const paramsValue = new URLSearchParams(window.location.search);
  const page = paramsValue.get("page");
  const limit = paramsValue.get("limit");

  const [currentPage, setCurrentPage] = useState(page ? Number(page) : 1);
  const [total, setTotal] = useState(1);
  // const total = 100;
  const itemsPerPage = limit ? limit : 12;

  useEffect(() => {
    //initial page loading adding the default page and limit values to url
    if (!page || !limit) {
      paramsValue.set("page", 1);
      paramsValue.set("limit", itemsPerPage);
      navigate(`${location.pathname}?${paramsValue.toString()}`);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (currentPage) {
      paramsValue.set("page", currentPage);
      paramsValue.set("limit", itemsPerPage);
      navigate(`${location.pathname}?${paramsValue.toString()}`);
    }
  }, [currentPage]);

  const {
    isLoading,
    isError,
    data: galleriesData,
    error,
  } = useQuery(
    [GalleryDataKey, location.search],
    async () => {
      const response = await fetch(
        `${API_BASE_URL}imageGallery/getAll${location.search}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${user.accessToken}`,
          },
        }
      );

      const data = await response.json();
      console.log("data articles 222", data);

      if (!response.ok) {
        console.log("Error", data);
        throw new Error(data.message);
      }
      setTotal(data?.total);
      return data?.data;
    },
    {
      enabled: page ? true : false,
      retry: 0,
      // retryDelay: 1000,
      // refetchOnWindowFocus: false,
    }
  );

  return (
    <>
      <div className={classes.galleryPageContainer}>
        <div className={classes.galleryBox}>
          <h3 className={classes.title}>{t("gallery")}</h3>
          {isLoading && <LoadingFile />}
          {!isLoading && !galleriesData?.length > 0 && <NoDataFound />}
          {!isLoading && galleriesData?.length > 0 && (
            <>
              <GalleryGridPage list={galleriesData} />

              <div className={classes.paginationComponent}>
                <PaginationCustom
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  totalItemsCount={total}
                  itemsCountPerPage={itemsPerPage}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default GalleryPage;
