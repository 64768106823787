import { createPortal } from "react-dom";

import NavbarList from "../navbar-list/NavbarList";
import InputForm from "components/forms/input/InputForm";

import classes from "./SideNavbar.module.css";
import Backdrop from "components/UI/modal/Backdrop";
import SideNavbarList from "./SideNavbarList";
import { useSelector } from "react-redux";

const SideNavbar = ({ onClose, NAVBAR_LINKS, languageValues }) => {
  const portalElement = document.getElementById("overlays");
  const onSubmitSearchFormHandler = (value) => {};
  const { language, direction } = useSelector((state) => state.language);

  return createPortal(
    <div
      className={`${classes.sideNavbar} ${
        direction == "rtl" ? classes.left : classes.right
      }`}
    >
      {/* <InputForm
        name="search"
        placeholder="Search"
        onSubmit={onSubmitSearchFormHandler}
        className={classes.searchInput}
      /> */}
      <SideNavbarList
        onClose={onClose}
        NAVBAR_LINKS={NAVBAR_LINKS}
        languageValues={languageValues}
      />
      {/* <NavbarList isSide onClose={onClose} className={classes.navbarList} /> */}
    </div>,
    portalElement
  );
};

export default SideNavbar;
