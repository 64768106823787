import React from "react";
import classes from "./NoDataFound.module.css";
import { useTranslation } from "react-i18next";

const NoDataFound = () => {
  const { t } = useTranslation();
  return (
    <div className={classes.container}>
      <h1 className={classes.noData}>{t("no_data_found")}</h1>
    </div>
  );
};

export default NoDataFound;
