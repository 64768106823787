import React from 'react'

const getDateInFormat = (dateValue) => {
    const date = new Date(dateValue);

    const optionsDate = { year: 'numeric', month: 'long', day: 'numeric' };
    const optionsTime = { hour: '2-digit', minute: '2-digit', hour12: true };

    const formattedDate = date.toLocaleDateString('en-US', optionsDate).toUpperCase();
    const formattedTime = date.toLocaleTimeString('en-US', optionsTime).toUpperCase();

    return `${formattedDate} AT ${formattedTime}`;
}

export default getDateInFormat

