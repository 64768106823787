import React, { useEffect, useState } from "react";
import classes from "./SideNavbarList.module.css";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { modalActions } from "store-redux/modal-slice";
import { userActions } from "store-redux/user-slice";
// import NavbarItem from "../navbar-list/navbar-item/NavbarItem";
import { Avatar, Badge, Button, Select } from "antd";
import Autocomplete from "react-google-autocomplete";
import {
  APP_PREFIX_PATH,
  DONATE_LINK,
  GOOGLE_MAP_API_KEY,
} from "configs/AppConfig";
import { useTranslation } from "react-i18next";
import { languageActions } from "store-redux/language-slice";
import NavbarItem from "./navbar-item/NavbarItem";
import { auth } from "configs/FirebaseConfig";

const { Option } = Select;

const SideNavbarList = ({
  className,
  onClose,
  NAVBAR_LINKS,
  languageValues,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { user, isLoggedIn } = useSelector((state) => state.user);
  const filters = useSelector((state) => state.filter);
  const { language } = useSelector((state) => state.language);
  const [selectedAddress, setSelectedAddress] = useState();

  useEffect(() => {
    if (filters) {
      setSelectedAddress(filters?.address);
    }
  }, [filters]);

  const setSigninModalHandler = () => {
    dispatch(
      modalActions.changeModalComponent({
        modalType: "signin",
      })
    );
  };

  const onClickSignoutHandler = () => {
    dispatch(userActions.logout());
    dispatch(modalActions.toggleModal());
    auth.signOut();
    navigate("/");
  };

  const placeholderStyle = {
    color: "black",
    fontSize: "14px",
    fontFamily: "Roboto",
    fontWeight: 400,
    paddingLeft: "8px",
  };

  const customArrow = (
    <img
      src="/news-img/icons/navbar-droparrow.svg"
      alt="arrow"
      style={{
        width: "15px",
        height: "7.5px",
      }}
    /> // Customize the arrow color as needed
  );
  const placeholderIcon = (
    <img
      src="/news-img/icons/language.svg"
      alt="arrow"
      style={{
        width: "24px",
        height: "24px",
        display: "flex",
        justifyContent: "center",
      }}
    />
  );

  const languageChangeHandler = (lang) => {
    console.log("lang selected", lang);
    dispatch(languageActions.toggleLanguage({ language: lang }));
  };

  return (
    <ul className={`${classes.navbarList} ${className}`}>
      <img
        src="/news-img/icons/close-red.svg"
        alt="close"
        className={classes.closeIcon}
        onClick={onClose}
      />

      <div className={classes.list}>
        {NAVBAR_LINKS?.map((link, index) => (
          <div className={classes.listItem}>
            <NavbarItem
              navItem
              onClick={onClose}
              isMenu={link.type == "menu"}
              menuList={link.menu}
              key={link.key ? link.key : link.to + index}
              pageLink={link.to}
            >
              <div className={classes.linkFlex}>
                {link.title}&nbsp;
                {link.type == "menu" && (
                  <img
                    src="/news-img/icons/dropdown-black.svg"
                    alt="arrow"
                    className={classes.blackArrow}
                  />
                )}
              </div>
            </NavbarItem>
          </div>
        ))}
        <Button
          className={classes.donateButton}
          onClick={() => window.open(DONATE_LINK, "_blank")}
        >
          {t("donate")}
          <img src="/news-img/donate-button.svg" />
        </Button>
        <div className={classes.listSelect}>
          <Select
            className="navbar-select"
            popupClassName="navbar-select-dropdown"
            value={language}
            onChange={(value) => languageChangeHandler(value)}
            onSelect={onClose}
            suffixIcon={customArrow}
            placeholder={placeholderIcon}
            // open={true}
            // placeholder={
            //   <LanguageIcon className="navbar-select-placeholder-icon" />
            // }
          >
            {languageValues?.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </div>
        {/* <div className={classes.listItem}>
          <NavbarItem
          //   onClick={setSigninModalHandler}
          >
            <img
              src="/img/icons/packages.svg"
              alt="packages"
              className={classes.navIcon}
            />
            Packages
          </NavbarItem>
        </div> */}
        {/* <hr className={classes.line} /> */}
      </div>
      <div className={classes.accountItem}>
        {!isLoggedIn && (
          // <NavbarItem onClick={setSigninModalHandler}>
          //   <img
          //     src="/img/icons/login2.svg"
          //     alt="login"
          //     className={classes.navIcon}
          //   />
          //   Login
          // </NavbarItem>
          <Button
            className={classes.loginButton}
            onClick={() => setSigninModalHandler()}
          >
            {t("login")}
          </Button>
        )}
        {isLoggedIn && (
          <Link
            onClick={onClose}
            to={`${APP_PREFIX_PATH}/my-account/my-articles`}
          >
            <div
              className={classes.checkedBySection}
              // onClick={() => navigate("/my-account", { replace: true })}
            >
              <Avatar
                size={36}
                src={user.photoURL ? user.photoURL : "/news-img/profile.svg"}
                // icon={<UserOutlined />}
              />
              {/* <div className={classes.circularDiv}>
                <img
                  src={"/news-img/back.svg"}
                  alt="profile"
                  className={classes.profilePic}
                />
              </div> */}
              <div className={classes.checkedByDetails}>
                <h1 className={classes.checkedByName}>
                  {user?.displayName ? user?.displayName : user?.firstName}
                </h1>
                <h2 className={classes.checkedByEmail}>{user.email}</h2>
              </div>
            </div>
          </Link>
        )}
      </div>
      {isLoggedIn && (
        <Button option="colored" onClick={() => onClickSignoutHandler()}>
          {t("logout")}
        </Button>
      )}
      {/* <div className={classes.logoutWrapper}>
        <Button
          option="uncolored"
          className={classes.logout}
          onClick={onClickSignoutHandler.bind()}
        >
          Logout
        </Button>
      </div> */}
    </ul>
  );
};

export default SideNavbarList;
