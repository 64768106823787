import { Link } from "react-router-dom";

import classes from "./NavbarLogo.module.css";
import { useDispatch } from "react-redux";
import { modalActions } from "store-redux/modal-slice";



const NavbarLogo = ({ onClick }) => {

  const dispatch = useDispatch()

  const openSignInModal = () => {
    dispatch(
      modalActions.changeModalComponent({
        modalType: `signin`,
        // modalType: `vehicleimageview${InventoryNo}`
      })
    );
  }

  return (
    <Link to="/" onClick={onClick} className={classes.logoContainer}>
      <img
        src="/news-img/logo-header.svg"
        alt="Logo"
        loading="lazy"
        className={classes.navbarLogo}
        // onClick={openSignInModal}
      />
    </Link>
  );
};

export default NavbarLogo;
