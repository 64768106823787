import { createSlice } from '@reduxjs/toolkit';
import { LANGUAGE } from 'helpers/constants';

import i18n from '../language-file/i18n'; // Import the initialized i18n instance

const initialState = {
    language: null,
    direction: "ltr",
};

const LANG_DIR = {
    en: 'ltr',
    ar: 'rtl',
    he: 'rtl'
}

const languageSlice = createSlice({
    name: 'language',
    initialState,
    reducers: {
        toggleLanguage(state, action) {
            if (action.payload.language) {
                state.language = action.payload.language;
                state.direction = LANG_DIR[action.payload.language]
                i18n.changeLanguage(action.payload.language);   //to change language in i18n
            };

            const filterJson = JSON.stringify(state);
            // save the user selected language in local storage
            localStorage.setItem(LANGUAGE, filterJson);
        },
        autoFillLanguage(state) {
            console.log('reached autofill')
            const storedLocation = JSON.parse(
                localStorage.getItem(LANGUAGE)
            );
            if (storedLocation) {
                console.log('if works')
                state.language = storedLocation.language;
                state.direction = storedLocation.direction;
            } else {
                console.log('else works')
                state.language = "en";
                state.direction = "ltr";
                const filterJson = JSON.stringify(state);
                localStorage.setItem(LANGUAGE, filterJson);
            }
        },
    }
});

export const languageActions = languageSlice.actions;
export default languageSlice.reducer;
