import { APP_NAME } from '../configs/AppConfig';


export const socialMediaData = [
  {
    name: "facebook",
    image: "/news-img/buttons/facebook.svg",
  },
  {
    name: "twitter",
    image: "/news-img/buttons/twitter.svg",
  },
  {
    name: "youtube",
    image: "/news-img/buttons/youtube.svg",
  },
  {
    name: "instagram",
    image: "/news-img/buttons/instagram.svg",
  },
  {
    name: "linkedin",
    image: "/news-img/buttons/linkedin.svg",
  },
  {
    name: "whatsapp",
    image: "/news-img/buttons/whatsapp.svg",
  },
];


export const USER_LOCAL_STORAGE_KEY = `${APP_NAME}_user`;
export const USER_EXPIRATION_TIME = `${APP_NAME}_user_expiration_time`;
export const USER_FILTER_VALUES = `${APP_NAME}_filter_values`;
export const LANGUAGE = `${APP_NAME}_language`;

