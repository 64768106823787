import React from "react";
import classes from "./OptOutForm.module.css";
import { Form, Input, InputNumber, notification, Select } from "antd";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import TextArea from "antd/es/input/TextArea";
import Button from "components/UI/button/Button";
import { API_BASE_URL } from "configs/AppConfig";
import { useMutation } from "@tanstack/react-query";
import { LoadingOutlined } from "@ant-design/icons";

const { Option } = Select;

const rules = {
  firstName: [
    {
      required: true,
      message: "Required",
    },
    {
      pattern: /^[a-zA-Z0-9 ]+$/,
      message: "First Name can only include letters, numbers, and spaces!",
    },
  ],
  lastName: [
    {
      required: false,
      message: "Required",
    },
    {
      pattern: /^[a-zA-Z0-9 ]+$/,
      message: "Last Name can only include letters, numbers, and spaces!",
    },
  ],
  email: [
    {
      required: true,
      message: "Required",
    },
    {
      type: "email",
      message: "Please enter a valid email",
    },
  ],
  number: [
    {
      required: true,
      message: "Required",
    },
  ],
  required: [
    {
      required: true,
      message: "Required",
    },
  ],
};

const OptOutForm = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const {
    isLoading: isSubmiLoading,
    isError: isSubmitError,
    mutateAsync: submitForm,
  } = useMutation(async (formData) => {
    const response = await fetch(`${API_BASE_URL}users/opt-out`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    });

    const data = await response.json();

    if (!response.ok) {
      console.log("Error", data);
      if (data.message.length > 0) {
        data.message.map((error) => {
          notification.error({
            message: t("error"),
            description: error,
          });
        });
      } else {
        notification.error({
          message: t("error"),
          description: data.message ? data.message : "An error occured.",
        });
      }
      throw new Error(data.message);
    }

    return data;
  });

  const onSubmit = () => {
    form.validateFields().then(async (values) => {
      values.phoneNumber = `+${values.phoneNumber}`;
      const response = await submitForm(values);
      if (response.success) {
        form.resetFields();
        notification.success({
          message: t("success"),
          description: "Opt Out request submitted successfully.",
        });
      }
    });
  };

  return (
    <div className={classes.container}>
      <div className={classes.formContainer}>
        <h1>{t("opt_out_form")}</h1>
        <div className={classes.formWrapper}>
          <div className="connectForm w-100">
            <Form
              layout="vertical"
              form={form}
              name="filter_form"
              className="ant-advanced-search-form w-100"
            >
              <Form.Item
                label={t("first_name")}
                name="firstName"
                required //to show asterick sign for mandatory
                rules={rules.firstName}
              >
                <Input placeholder={t("first_name")} />
              </Form.Item>
              <Form.Item
                label={t("last_name")}
                name="lastName"
                rules={rules.lastName}
              >
                <Input placeholder={t("last_name")} />
              </Form.Item>
              <Form.Item
                label={t("enter_email")}
                name="email"
                rules={rules.email}
                required //to show asterick sign for mandatory
              >
                <Input placeholder={t("enter_email")} />
              </Form.Item>
              <Form.Item label={t("enter_phone")} name="phoneNumber">
                <PhoneInput
                  // inputProps={{
                  //   name: "mobileNumber",
                  // }}
                  country={"us"}
                  // value={mobileNumber}
                  // onChange={(value) => setMobileNumber(`+${value}`)}
                  // inputClass={"react-tel-input"}
                  // dropdownStyle={{ width: "50px" }}
                  enableSearch={true}
                  specialLabel=""
                  disableDropdown={false}
                  countryCodeEditable={true}
                  // placeholder={t("enter_phone")}
                />
                {/* <Input placeholder={t("enter_phone")} /> */}
              </Form.Item>
              <Form.Item
                label={t("zip_code")}
                name="zipCode"
                required //to show asterick sign for mandatory
                rules={rules.number}
              >
                <Input
                  type="number"
                  placeholder={t("zip_code")}
                  className="w-100"
                />
              </Form.Item>
              <Form.Item
                label={t("enter_description")}
                name="description"
                required //to show asterick sign for mandatory
                rules={rules.required}
              >
                <TextArea placeholder={t("enter_description")} rows={4} />
              </Form.Item>
              <Form.Item
                label={t("verify_resident")}
                name="residentOfCalifornia"
                required //to show asterick sign for mandatory
                rules={rules.required}
              >
                <Select
                  style={{ width: "100%" }}
                  placeholder={t("select_yes_or_no")}
                  className="select-no-border-radius"
                >
                  <Option value="yes">{t("yes")}</Option>
                  <Option value="no">{t("no")}</Option>
                </Select>
              </Form.Item>
            </Form>
          </div>
          <Button
            className={classes.submitButton}
            onClick={() => onSubmit()}
            disabled={isSubmiLoading}
          >
            {isSubmiLoading && <LoadingOutlined />}
            {t("submit")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default OptOutForm;
